import GenericModal from '@/components/Common/GenericModal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import * as Sentry from '@sentry/browser';

import GenericButton from '@/components/Common/GenericButton';
import _ from 'underscore';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import useClientId from '@/hooks/router/useClientId';
import { getTranslatedText } from '@/common';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import { ToggleColumnSelectorContent } from '../../JobsView/TableColumnSelector';
import { ColumnDisplay } from '../../JobsView/JobView/ProjectProfilesTab/columns/useProfilesTableColumns';

interface ExportProfileCSVModalProps {
  jobId?: string;
  open: boolean;
  onClose: () => void;
  onExport: (fields: { type: string; customFieldId?: string }[]) => void;
}

const DEFAULT_FIELDS = [
  { name: 'firstname', hidden: false },
  { name: 'lastname', hidden: false },
  { name: 'title', hidden: false },
  { name: 'company', hidden: false },
  { name: 'source', hidden: false },
  { name: 'hasBeenCandidate', hidden: false },
  { name: 'initials', hidden: true },
  { name: 'email', hidden: false },
  { name: 'phoneNumber', hidden: true },
  { name: 'linkedin', hidden: false },
];

const ExportProfileCSVModal: React.FC<ExportProfileCSVModalProps> = ({
  jobId,
  open,
  onClose,
  onExport,
}) => {
  const { t } = useTranslation();
  const [columnsDisplay, setColumnsDisplay] = useState<ColumnDisplay[]>([]);
  const configurationParams = useMergedConfigurationParams();

  const clientId = useClientId();

  const { profileCustomFields } = useClientProfileCustomFields(clientId);

  const displayableColumnGroups = useMemo(() => {
    const customFieldsColumns = _.map(profileCustomFields, (customField) => {
      return {
        id: customField.id,
        name: getTranslatedText(customField.title),
        hidden: true,
        available: true,
        isCustomField: true,
      };
    });
    const groups = [
      {
        groupId: 'default',
        title: 'General',
        columns: [
          ..._.map(
            _.filter(DEFAULT_FIELDS, ({ name }) => {
              if (
                (configurationParams?.canUseProfileColumnsTitleAndCompany !==
                  'true' ||
                  !jobId) &&
                ['title', 'company'].includes(name)
              ) {
                return false;
              }
              if (
                (configurationParams?.canUseProfileColumnsSourceAndCandidate !==
                  'true' ||
                  !jobId) &&
                ['source', 'hasBeenCandidate'].includes(name)
              ) {
                return false;
              }
              return true;
            }),
            (field) => {
              return {
                id: field.name,
                name: t(`reveal.missions.columnSelectors.${field.name}`),
                hidden: field.hidden,
                available: true,
                // isCustomField: false,
              };
            },
          ),
          ...(jobId
            ? [
                {
                  id: 'mission-comment',
                  name: t('reveal.missions.columnSelectors.comment'),
                  hidden: false,
                  available: true,
                  // isCustomField: false,
                },
              ]
            : []),
          {
            id: 'stage',
            name: t('reveal.missions.columnSelectors.stage'),
            hidden: true,
            available: true,
          },
        ],
      },
      {
        groupId: 'profile-custom-fields',
        title: t('reveal.missions.columnSelectorGroups.customFields'),
        columns: customFieldsColumns,
      },
    ];
    return groups;
  }, [profileCustomFields, t, jobId, configurationParams]);

  const currentDisplayColumns = useMemo(() => {
    const allColumns = _.map(displayableColumnGroups, (group) =>
      _.map(group.columns, (column) => ({
        ...column,
        groupId: group.groupId,
      })),
    ).flat();
    const columns = _.reduce(
      allColumns,
      (aggregator, currentDefaultColumn) => {
        const newAggregator = [
          ...aggregator,
          {
            ...currentDefaultColumn,
            name: currentDefaultColumn.name || currentDefaultColumn.id,
            hidden: currentDefaultColumn.hidden,
            groupId: currentDefaultColumn.groupId,
            targetOrderIndex: undefined,
            available: true,
          },
        ];
        return newAggregator;
      },
      [],
    );
    return columns;
  }, [displayableColumnGroups]);

  const { defaultGroup, otherColumnGroups } = useMemo(() => {
    const defaultColumnGroup = _.findWhere(displayableColumnGroups, {
      groupId: 'default',
    });

    const otherGroups = _.filter(
      displayableColumnGroups,
      ({ groupId, columns }) => groupId !== 'default' && !_.isEmpty(columns),
    );

    return { defaultGroup: defaultColumnGroup, otherColumnGroups: otherGroups };
  }, [displayableColumnGroups]);

  useEffect(() => {
    if (!columnsDisplay.length) {
      setColumnsDisplay(currentDisplayColumns);
    }
  }, [currentDisplayColumns, columnsDisplay.length]);

  const currentColumnValuesById = useMemo(() => {
    return _.reduce(
      columnsDisplay || [],
      (agg, column) => {
        // eslint-disable-next-line
        agg[column.id] = column;
        return agg;
      },
      {} as Record<string, ColumnDisplay>,
    );
  }, [columnsDisplay]);

  const handleCheckboxChange = useCallback(
    (updatedColumn) => {
      const newColumnsDisplay = _.map(columnsDisplay || [], (column) =>
        column?.id === updatedColumn.id
          ? { ...column, hidden: !column.hidden }
          : column,
      );
      setColumnsDisplay(newColumnsDisplay);
    },
    [columnsDisplay],
  );

  return (
    <GenericModal
      size='small'
      open={open}
      onClose={onClose}
      closeOnEscape
      closeOnDimmerClick
    >
      <Modal.Header>
        {t('reveal.missions.mission.modals.exportCSVModal')}
      </Modal.Header>
      <Modal.Content>
        <ToggleColumnSelectorContent
          defaultColumns={defaultGroup?.columns || []}
          otherColumns={otherColumnGroups}
          currentColumnValuesById={currentColumnValuesById}
          handleCheckboxChange={handleCheckboxChange}
        />
      </Modal.Content>
      <Modal.Actions>
        <GenericButton primacy='secondary' onClick={onClose}>
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          onClick={() => {
            try {
              const selectedColumns = columnsDisplay.filter(
                (column) => !column.hidden,
              );
              const fields = _.map(selectedColumns, (column) => {
                return {
                  type: column?.isCustomField ? 'custom-field' : column.id,
                  customFieldId:
                    column.groupId === 'profile-custom-fields'
                      ? column.id
                      : undefined,
                };
              });
              onExport(fields);
              onClose();
            } catch (e) {
              Sentry.captureException(e);
            }
          }}
          primacy='primary'
        >
          {t('reveal.missions.mission.modals.exportButton')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default ExportProfileCSVModal;
