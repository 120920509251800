import gql from 'graphql-tag';
import Attachment from './Attachment';
import ContactData from './ContactData';
import ContactFlow from './ContactFlow';
import ProfileMissionInfoWithMission from './ProfileMissionInfoWithMission';
import ProfileProjectState from './ProfileProjectState';
import ProfileSequenceInfoWithSequence from './ProfileSequenceInfoWithSequence';
import RevealResumeData from './RevealResumeData';
import TextWithEnrichmentsAndStack from './TextWithEnrichmentsAndStack';
import Sources from './Sources';
import CustomFieldsValues from './CustomFieldsValues';

export default gql`
  fragment SearchPoolProfile on SearchPoolProfile {
    id
    creationTimestamp
    lastATSRefreshTimestamp
    sent
    locked
    answerDetected
    email
    similarProfilesSearchString
    currentProject {
      ...ProfileProjectState
    }
    currentSequenceInfo {
      ...ProfileSequenceInfoWithSequence
    }
    missionsInfo {
      ...ProfileMissionInfoWithMission
    }
    resumeData {
      ...RevealResumeData
    }
    attachments {
      ...Attachment
      file {
        extension
        url
      }
    }
    contactFlow {
      ...ContactFlow
    }
    contactData {
      ...ContactData
    }
    availability {
      nextAvailabilityDate
      medicalExaminationExpirationDate
      constructionCardExpirationDate
    }
    lastEventTimestamp
    views {
      timeline {
        author {
          firstname
          lastname
          email
          photoLink
        }
        date
        timestamp
        origin {
          type
          URL
        }
      }
      statsByRecruiter {
        author {
          firstname
          lastname
          email
          photoLink
        }
        firstViewTimestamp
        lastViewTimestamp
        count
      }
    }
    companyDealState {
      type
      customCompanyDealStateId
    }
  }
  ${Attachment}
  ${ContactData}
  ${ContactFlow}
  ${ProfileProjectState}
  ${ProfileSequenceInfoWithSequence}
  ${ProfileMissionInfoWithMission}
  ${RevealResumeData}
`;

// This is a fake fragment because every ATS has their own type
const ATSApplicationJobAndStagesFragment = `
  applications {
    jobs {
      title
    }
    status {
      sourceStatusId
      rejection {
        reason {
          sourceRejectionReasonName
        }
        lastStage {
          sourceStageName
        }
      }
      stage {
        sourceStageName
      }
    }
  }
`;

export const MiniSearchPoolProfile = gql`
  fragment MiniSearchPoolProfile on SearchPoolProfile {
    id
    missionsInfo {
      ...ProfileMissionInfoWithMission
    }
    name
    resumeData {
      firstname
      lastname
      photoLink
      email
      phoneNumber
      headline {
        ...TextWithEnrichmentsAndStack
      }
      currentTitle {
        ...TextWithEnrichmentsAndStack
      }
      currentCompany {
        name
      }
      sources {
        ...Sources
      }
      sourceData {
        linkedinRecruiter {
          id
        }
        adventure {
          type
          sourceCandidateId
          ${ATSApplicationJobAndStagesFragment}
        }
        ashby {
          type
          sourceCandidateId
          ${ATSApplicationJobAndStagesFragment}
        }
        greenhouse {
          type
          sourceCandidateId
          ${ATSApplicationJobAndStagesFragment}
        }
        smartrecruiters {
          type
          sourceCandidateId
          ${ATSApplicationJobAndStagesFragment}
        }
        lever {
          type
          sourceCandidateId
          ${ATSApplicationJobAndStagesFragment}
        }
        recruitee {
          type
          sourceCandidateId
          ${ATSApplicationJobAndStagesFragment}
        }
        teamtailor {
          type
          sourceCandidateId
          ${ATSApplicationJobAndStagesFragment}
        }
        workable {
          type
          sourceCandidateId
          ${ATSApplicationJobAndStagesFragment}
        }
        welcomekit {
          type
          sourceCandidateId
          ${ATSApplicationJobAndStagesFragment}
        }
        boondmanager {
          type
          sourceCandidateId
          ${ATSApplicationJobAndStagesFragment}
        }
      }
      customFields {
        ...CustomFieldsValues
      }
    }
    contactFlow {
      ...ContactFlow
    }
    currentSequenceInfo {
      sequenceId
      lastActionDate
      author {
        firstname
        lastname
      }
      sequence {
        id
        title
      }
    }
    privacyState {
      markedAsDoNotContact
    }
  }
  ${ContactFlow}
  ${TextWithEnrichmentsAndStack}
  ${ProfileMissionInfoWithMission}
  ${Sources}
  ${CustomFieldsValues}
`;

export const SearchPoolProfileWithoutResumeData = gql`
  fragment SearchPoolProfile on SearchPoolProfile {
    id
    creationTimestamp
    lastATSRefreshTimestamp
    sent
    locked
    answerDetected
    email
    similarProfilesSearchString
    currentProject {
      ...ProfileProjectState
    }
    currentSequenceInfo {
      ...ProfileSequenceInfoWithSequence
    }
    missionsInfo {
      ...ProfileMissionInfoWithMission
    }
    attachments {
      ...Attachment
    }
    contactFlow {
      ...ContactFlow
    }
    contactData {
      ...ContactData
    }
  }
  ${Attachment}
  ${ContactData}
  ${ContactFlow}
  ${ProfileProjectState}
  ${ProfileSequenceInfoWithSequence}
  ${ProfileMissionInfoWithMission}
`;

export const SearchPoolProfileMissions = gql`
  fragment SearchPoolProfile on SearchPoolProfile {
    id
    currentProject {
      ...ProfileProjectState
    }
    missionsInfo {
      ...ProfileMissionInfoWithMission
    }
  }
  ${ProfileProjectState}
  ${ProfileMissionInfoWithMission}
`;
